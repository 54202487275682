<template>
  <component
    :is="props.tag"
    class="flex items-center text-xs"
  >
    <slot name="dot">
      <span
        v-if="!props.isQuantityMoreThanZero && props.showNotAvailableDot"
        class="mr-2 inline-block size-3 shrink-0 rounded-full bg-notification-error"
      />
    </slot>

    <slot name="middle-content" />

    <slot name="label">
      <HtmlParser
        v-if="props.availabilityLabel"
        :html="props.availabilityLabel"
        tag="span"
      />
    </slot>
  </component>
</template>

<script setup lang="ts">
interface Props {
  tag?: string
  isQuantityMoreThanZero: boolean
  availabilityLabel: string
  showNotAvailableDot?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  tag: 'p',
  showNotAvailableDot: true,
})
</script>
